import React from "react"
import Bio from '../components/ui/bio'
import {Helmet} from 'react-helmet'
import SEO from '../components/seo'
import Layout from "../layouts/layout-fixed"
import { graphql } from 'gatsby'
// import background from "../img/DSC01544.jpeg" // Tell Webpack this JS file uses this image
// console.log(background) // /logo.84287d09.png

const Index = ({data}) =>
  <Layout>
    <SEO title={data.site.siteMetadata.author} />
    <Helmet htmlAttributes={{ class:'home fixed' }} />
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        height: "100%",
      }}
      className="fade-in"
        >
        <h1>
          Aloha
        </h1>
        <p>
          I'm Stef'
        </p>
        <Bio site={data.site}/>
    </div>
  </Layout>
export default Index;

export const query = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title,
        subtitle,
        author,
        social {
          twitter,
          instagram,
          gitlab,
          strava
        },
      }
    }
  }
`
